.DetailLayout_Wrapper{
    max-height: calc(100vh - 65px);
}

@media (max-width: 767px) {
  .detail-layout-main {
    display: flex !important;
    flex-direction: column !important;
    flex: auto !important;
  }
  .detail-layout-section {
    display: flex !important;
    flex-direction: row !important;
  }
  .detail-layout-aside {
    width: 100vw !important;
    order: -1 !important;
    display: block !important;
  }
  .detail-layout-aside > div {
    width: 100vw;
  }
  .detail-layout-aside > div > div {
    display: flex;
    flex-direction: row;
  }
}